<script>
import get from 'lodash-es/get'
import { extend, ValidationObserver } from 'vee-validate'
import { computed, ref, inject, watch, reactive } from 'vue'
import modal from '/~/core/mdl'
import { RecroomEvent } from '/~rec/core/event'
import RenderBanner from '/~rec/components/render/render-banner.vue'
import ui from '/~/core/ui'
import cdn from '/~/utils/cdn'
import { formatDate, createDate, isSameDay } from '/~/utils/format/date'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseDatepicker from '/~/components/base/datepicker/base-datepicker.vue'
import BaseSelectAsync from '/~/components/base/select-async/base-select-async.vue'
import BaseTextarea from '/~/components/base/textarea/base-textarea.vue'
import TypeSwitch from '../form/type-switch.vue'
import UserSelector from '/~rec/components/users/user-selector.vue'
import UsersSelectedDesktop from '/~rec/components/users/users-selected-desktop.vue'
import FormElementLabel from '/~rec/components/form/form-element-label.vue'
import { useEvents } from '/~rec/composables/events'
import { useRecProfile } from '/~rec/composables/profile'
import { useDirectory } from '/~rec/composables/directory'
import { useBackendValidation } from '/~rec/composables/backend-validation'
import { useForm } from '/~/composables/base/use-form'

export default {
  name: 'rec-event-form',
  components: {
    RenderBanner,
    BaseDatepicker,
    TypeSwitch,
    BaseButton,
    BaseSelectAsync,
    BaseTextarea,
    UserSelector,
    UsersSelectedDesktop,
    FormElementLabel,
    ValidationObserver,
  },
  props: {
    date: {
      type: Object,
      default: null,
    },
    event: {
      type: Object,
      default: null,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const loadingEvent = inject('eventLoading', ref(false))
    const { findAddress } = useEvents()
    const { isMeLimitedUser } = useRecProfile()
    const { backendErrors, processBackendErrors } = useBackendValidation()
    const { validationObserverRef } = useForm()

    const usersTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const processing = ref(false)
    const defaultLocationOptions = ref([])

    const form = reactive({
      startDate: '',
      endDate: '',
      description: '',
      startTime: '',
      endTime: '',
      location: '',
      timezone: '',
      name: '',
      private: '',
      bannerUrl: '',
      members: [],
    })
    const btnLabel = computed(() => (props.event ? 'Save' : 'Create Event'))
    const { getTodayEvents } = useDirectory()
    const dateFormat = 'daymonthyear'
    const time24Format = 'time24'
    const time12Format = 'time'
    const timeFormat = computed(() => (ui.mobile ? time24Format : time12Format))
    const fullDateFormat = computed(() =>
      ui.mobile ? 'DD MMM YYYY HH:mm' : 'DD MMM YYYY HH:mm a'
    )
    const typeOptions = ref([
      {
        value: false,
        label: 'Public Event',
        description:
          'All members will see this event and be able to participate.',
        icon: 'rec/planet',
      },
      {
        value: true,
        label: 'Private Event',
        description:
          'Only invited members will see this event and be able to participate.',
        icon: 'rec/lock-solid',
      },
    ])

    const startDate = computed(() => {
      if (form.startDate && form.startTime) {
        return createDate(
          `${form.startDate} ${form.startTime}`,
          fullDateFormat.value
        ).utc(true)
      } else if (form.startDate && !form.startTime) {
        return createDate(form.startDate, dateFormat)
      }
      return null
    })

    const minEndDate = computed(() => {
      if (startDate.value) {
        return startDate.value.add(15, 'minute')
      }
      return null
    })

    const suggestedEndDate = computed(() => {
      if (startDate.value) {
        return startDate.value.add(1, 'hour')
      }
      return null
    })

    const endDate = computed(() => {
      if (form.endDate && form.endTime) {
        return createDate(
          `${form.endDate} ${form.endTime}`,
          fullDateFormat.value
        ).utc(true)
      } else if (form.endDate && !form.endTime) {
        return createDate(form.endDate, 'DD MMM YYYY').utc(true)
      }
      return null
    })

    const disabledEndDates = computed(() => ({
      to: formatDate(dateFormat, minEndDate.value),
    }))

    const endTimeFrom = computed(() => {
      if (
        isSameDay(startDate.value, endDate.value) &&
        Boolean(form.startTime)
      ) {
        return formatDate(time24Format, minEndDate.value)
      }
      return '00:00'
    })

    watch(startDate, () => {
      const isDatesEqual = isSameDay(startDate.value, endDate.value)
      const { endTime, startTime } = form

      if (isDatesEqual && !endTime && startTime) {
        form.endDate = formatDate(dateFormat, suggestedEndDate.value)
        form.endTime = formatDate(timeFormat.value, suggestedEndDate.value)
      } else if (endDate.value.isBefore(minEndDate.value)) {
        form.endDate = formatDate(dateFormat, minEndDate.value)
        if (isDatesEqual && endTime) {
          form.endTime = formatDate(timeFormat.value, minEndDate.value)
        }
      }
    })

    extend('timeAfter', {
      message() {
        return `Event end time should be after ${startDate.value.format(
          timeFormat.value
        )}`
      },
      validate() {
        if (isSameDay(startDate.value, endDate.value)) {
          return endDate.value.isAfter(startDate.value)
        }
        return true
      },
    })

    watch(
      loadingEvent,
      (loadingEvent) => {
        if (!loadingEvent) {
          if (props.isEdit) {
            const eventStartDate = createDate(props.event.raw.starts_at)
            const eventEndDate = createDate(props.event.raw.ends_at)

            form.startDate = props.event.dateStartEvent
            form.startTime = formatDate(timeFormat.value, eventStartDate)
            form.endTime = formatDate(timeFormat.value, eventEndDate)
            form.endDate = props.event.dateEndEvent
            form.description = props.event.description
            form.location = props.event.location
            form.timezone = props.event.timeZone
            form.name = props.event.name
            form.private = props.event.isPrivate
            form.bannerUrl = props.event.image
            form.members = props.event.members.hits
            defaultLocationOptions.value = [
              {
                label: props.event.location,
              },
            ]
          } else {
            form.startDate = formatDate(dateFormat, new Date())
            form.endDate = formatDate(dateFormat, new Date())
            form.timezone = usersTimezone
            form.private = isMeLimitedUser.value
            form.bannerUrl = cdn('/img/banner50').url()
          }
        }
      },
      { immediate: true }
    )

    return {
      isMeLimitedUser,
      findAddress,
      form,
      processing,
      btnLabel,
      dateFormat,
      timeFormat,
      loadingEvent,
      typeOptions,
      getTodayEvents,
      formatDate,
      createDate,
      startDate,
      endDate,
      disabledEndDates,
      endTimeFrom,
      defaultLocationOptions,
      backendErrors,
      processBackendErrors,
      ui,
      validationObserverRef,
    }
  },
  methods: {
    async onSubmit() {
      const { form } = this

      this.processing = true
      this.backendErrors = {}

      const location = this.form.location
      const data = {
        banner_url: form.bannerUrl,
        private: form.private ? 1 : 0,
        type: 'custom',
        name: form.name,
        startsAt: new Date(`${form.startDate} ${form.startTime}`).toUTCString(),
        endsAt: new Date(`${form.endDate} ${form.endTime}`).toUTCString(),
        start_timezone: form.timezone,
        location:
          typeof location === 'object' ? get(location, 'label') : location,
        description: form.description,
        members: form.members.map((member) => {
          return {
            id: member.id,
            status: member.status || 'invited',
          }
        }),
      }

      try {
        let event = this.event

        if (event) {
          await this.event.edit(data)
        } else {
          event = await RecroomEvent.create(data)
        }
        this.processing = false
        this.$emit('success', event)
        event.members.getData()
      } catch (error) {
        this.processBackendErrors(error, { data })
      } finally {
        this.processing = false
        this.getTodayEvents()
      }
    },
    openUserSelectModal() {
      modal.show('rec-users-manager', {
        props: {
          title: 'Event members',
          multi: true,
          selectable: true,
          getCurrentUsers: () => this.form?.members || [],
          filter: (users) =>
            users.filter((user) =>
              this.form.members.map((u) => u.id).includes(user.id)
            ),
          disabledLabel: 'Already invited',
          showAddList: true,
        },
        on: {
          'user-select': (users) => {
            this.form.members = users
          },
        },
      })
    },
  },
}
</script>

<template>
  <validation-observer
    v-slot="{ handleSubmit }"
    ref="validationObserverRef"
    slim
  >
    <form
      v-if="!loadingEvent && form"
      class="flex flex-col"
      @submit.prevent="handleSubmit(onSubmit)"
    >
      <div class="flex-1">
        <render-banner
          v-model="form.bannerUrl"
          class="mx-auto my-5 h-[140px] w-[140px] rounded-xl sm:mt-0"
          :disabled="processing"
          button
        />

        <type-switch
          v-if="!isMeLimitedUser"
          v-model="form.private"
          :options="typeOptions"
          :disabled="processing"
          class="mb-[30px]"
        />

        <form-element-label label="Event name" icon="rec/edit-contour" />

        <base-textarea
          v-model="form.name"
          :validation="{
            rules: 'required',
            name: 'name',
          }"
          :error="backendErrors.name"
          :maxlength="80"
          :disabled="processing"
          required
          name="name"
          look="recroom"
          class="mb-5"
        />

        <div class="flex flex-wrap items-start justify-around space-y-2.5">
          <form-element-label label="Event time" icon="rec/clocks" :size="19" />
          <div class="flex w-full">
            <div class="mr-2.5 grow overflow-hidden rounded-lg xxs:min-w-40">
              <base-datepicker
                v-model="form.startDate"
                :validation="{
                  rules: 'required',
                  name: 'start date',
                  mode: 'aggressive',
                }"
                required
                :format="dateFormat"
                :error="backendErrors.startsAt"
                :disabled="processing"
                :popup-style="{
                  zIndex: 999,
                }"
                nolabel
                name="startDate"
                class="date-field"
                :disabled-dates="{
                  to: createDate(),
                }"
                :min-date-mobile="new Date().toISOString().split('T')[0]"
              />
            </div>
            <div class="grow overflow-hidden rounded-lg">
              <base-datepicker
                v-model="form.startTime"
                :validation="{
                  rules: 'required',
                  name: 'start time',
                }"
                :error="backendErrors.startsAt"
                :format="timeFormat"
                :disabled="processing"
                :popup-style="{
                  zIndex: 999,
                }"
                placeholder="Start Time"
                name="startTime"
                type="time"
                required
                nolabel
                autofocus
                class="date-field"
                :step="ui.mobile ? 1 : 15"
                :clearable="!ui.mobile"
              />
            </div>
          </div>
          <div class="flex w-full">
            <div class="mr-2.5 grow overflow-hidden rounded-lg xxs:min-w-40">
              <base-datepicker
                v-model="form.endDate"
                :validation="{
                  rules: 'required',
                  name: 'end date',
                }"
                required
                :format="dateFormat"
                :error="backendErrors.endsAt"
                :disabled="processing"
                :disabled-dates="disabledEndDates"
                :popup-style="{
                  zIndex: 999,
                }"
                nolabel
                name="endDate"
                class="date-field"
                :min-date-mobile="formatDate('daymonthyear', form.startDate)"
              />
            </div>
            <div class="grow overflow-hidden rounded-lg">
              <base-datepicker
                v-model="form.endTime"
                :validation="{
                  rules: 'required|timeAfter',
                  name: 'end time',
                }"
                :error="backendErrors.endsAt"
                :format="timeFormat"
                :disabled="processing"
                placeholder="End Time"
                :start="endTimeFrom"
                :popup-style="{
                  zIndex: 999,
                }"
                name="endTime"
                type="time"
                required
                nolabel
                autofocus
                class="date-field"
                :step="ui.mobile ? 1 : 15"
                :clearable="!ui.mobile"
              />
            </div>
          </div>
        </div>

        <div class="mb-[30px] mt-2.5 w-full">
          <base-datepicker
            v-model="form.timezone"
            :validation="{
              rules: 'required',
              name: 'timezone',
            }"
            :error="backendErrors.start_timezone"
            :disabled="processing"
            name="timezone"
            type="timezone"
            required
            no-label
            class="date-field"
          />
        </div>

        <form-element-label
          label="Event location"
          icon="rec/location-pin"
          :size="18"
          class="mb-2.5"
        />

        <base-select-async
          :value="form.location"
          :validation="{
            rules: 'required',
            name: 'location',
          }"
          :error="backendErrors.location"
          :disabled="processing"
          :fetch="findAddress"
          :default-options="defaultLocationOptions"
          required
          name="location"
          class="mb-[30px]"
          look="rounded"
          nolabel
          floated
          placeholder="Type location"
          @input="form.location = $event"
        />

        <form-element-label
          label="Event description"
          icon="rec/book"
          :size="18"
        />

        <base-textarea
          v-model="form.description"
          :validation="{
            rules: 'required',
            name: 'description',
          }"
          :error="backendErrors.description"
          :disabled="processing"
          :maxlength="400"
          required
          name="description"
          look="recroom"
          class="mb-11"
        />
        <template v-if="form.private || isEdit">
          <form-element-label
            label="Members"
            icon="rec/group-event"
            :size="18"
            class="mb-2.5"
          />

          <user-selector
            v-if="ui.desktop"
            :multi="true"
            :users="form.members"
            @user-select="form.members = $event"
          />

          <div
            v-else
            class="flex min-h-12 cursor-pointer rounded-lg bg-gray-50 p-2.5"
            :class="{
              'pointer-events-none opacity-75': processing,
            }"
            @click="openUserSelectModal"
          >
            <users-selected-desktop
              :selected-users="form.members"
              :removable="false"
              multi
            />
          </div>
        </template>
      </div>

      <div class="mt-auto flex justify-center py-5">
        <base-button type="submit" :disabled="processing" :loading="processing">
          {{ btnLabel }}
        </base-button>
      </div>
    </form>
  </validation-observer>
</template>

<style lang="scss">
.date-field {
  .base-field__entry {
    @apply min-h-14 cursor-pointer rounded-lg border-transparent bg-gray-50;
  }

  .mx-input {
    @apply bg-gray-50;
  }

  @screen md {
    .base-field__entry,
    .mx-input {
      @apply bg-white;
    }
  }

  .base-field__entry--error {
    @apply border-red-700;
  }
}
</style>
